// @flow strict
import React from 'react';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';

const NotFoundTemplate = () => {
  const { title, subtitle } = useSiteMetadata();

  return (
    <Layout title={`Privacy Agreement - ${title}`} description={subtitle}>
      <Sidebar />
      <Page title="Privacy Agreement">
        <div id="privacy-outlet"></div>
        <Helmet>
          <script
            src={withPrefix('js/privacy-loader.js')}
            type="text/javascript"
          />
        </Helmet>
      </Page>
    </Layout>
  );
};

export default NotFoundTemplate;
